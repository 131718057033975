<template>
  <section id="professional-path" class="overflow-hidden mt-5" data-aos="fade-up fade-down">
    <v-row>
      <v-col cols="12">
        <base-heading class="text-uppercase primary--text">
          Professional Path
        </base-heading>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" v-for="path in paths" :key="path.company">
        <v-hover v-slot="{ hover }" close-delay="100">
          <v-card :elevation="hover ? 8 : 0" :class="{ 'on-hover': hover }"
            class="mx-auto d-flex align-center justify-center pa-2 custom-cursor" max-width="344" height="200"
            color="secondary" flat>
            <v-img :src="require(`@/assets/company_logos/${path.image}.png`)" contain></v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import paths from "@/assets/data/professionalPath.json";
export default {
  name: "ProfessionalPath",
  data: () => ({
    paths: paths,
  }),
};
</script>

<style></style>
